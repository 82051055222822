import React, { forwardRef } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { BsMenuButtonWideFill } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectDownloadReportLoading, setDetectedLocationDetail, toggleShowExportPdfModal } from '../detectedLocationSlice';
import { DetectedLocationData } from '../detectedLocationType';
const CustomToggle = forwardRef<
  HTMLDivElement,
  {
    children: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  }
>(function CustomToggle({ children, onClick }, ref) {
  return (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}>
      {children}
    </div>
  );
});

type props = {
  rowData: DetectedLocationData;
};
function ActionColumn({ rowData }: props) {
  const dispatch = useAppDispatch();

  const isDownloadLoading = useAppSelector(selectDownloadReportLoading);

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        <Button
          variant="primary"
          onClick={() => {
            dispatch(setDetectedLocationDetail(rowData));
          }}>
          <BsMenuButtonWideFill />
        </Button>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <NavLink to={`/detected-locations/${rowData.id}`} className="text-decoration-none">
          <Dropdown.Item
            as={'button'}
            onClick={() => {
              dispatch(setDetectedLocationDetail(rowData));
            }}>
            Detail
          </Dropdown.Item>
        </NavLink>
        <Dropdown.Item
          disabled={isDownloadLoading}
          onClick={() => {
            dispatch(toggleShowExportPdfModal(true));
            dispatch(setDetectedLocationDetail(rowData));
          }}
          as={'button'}>
          {isDownloadLoading ? 'Loading…' : 'Export PDF'}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default ActionColumn;
