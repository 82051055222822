import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { DateTime } from 'luxon';
import { Container } from 'react-bootstrap';
import { useForm, SubmitHandler } from 'react-hook-form';
import { selectDetectedLocationDetail, selectDownloadReportLoading, selectExportPdfModal, toggleShowExportPdfModal } from '../detectedLocationSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import SignPad from '../../../components/SignaturePad/SignPad';
import { downloadReportPdf, saveAsDraftPdf } from '../detectedLocationApi';
import { PdfFormValue } from '../detectedLocationType';
import { message } from 'antd';
import io from 'socket.io-client';
import { useEffect, useRef } from 'react';

type FormValues = {
  typeOfFacility: string;
  descriptionOfDefect: string;
  quantity: string;
  measurement: string;
  causeOfDetect: string;
  recommendation: string;
  inspectedBy: string;
  inspectedBySignature: string;
  checkedBy: string;
  checkedBySignature: string;
  reportedVia: string;
  acknowledgement: string;
  defectsVerifiedBy: string;
  defectsVerifiedBySignature: string;
  defectsVerifiedByDate: string;
  instructions: string;
  WSONo: string;
  contractNo: string;
  urgentDefect: string;
  observationKIV: string;
  repaidMethod: string;
  repeatedDefect: string;
  roadName: string;
  sector: string;
  element: string;
  defect: string;
  defectSize: string;
};

// eslint-disable-next-line react/prop-types
const PdfInformationFormModal = ({
  onData,
  handleFetchDataPdf,
  pixelData
}: {
  onData: (data: any) => void;
  handleFetchDataPdf: () => void;
  pixelData?: any;
}) => {
  const detectedLocationDetail = useAppSelector(selectDetectedLocationDetail);
  const isShowModal = useAppSelector(selectExportPdfModal);
  const isLoading = useAppSelector(selectDownloadReportLoading);
  const dispatch = useAppDispatch();
  const { register, handleSubmit, setValue, reset, control } = useForm<PdfFormValue>();
  const [messageApi, contextHolder] = message.useMessage();
  const socket = useRef<any>(null);
  useEffect(() => {
    if (detectedLocationDetail) {
      reset({
        typeOfFacility: detectedLocationDetail?.exportData?.typeOfFacility || '',
        descriptionOfDefect: detectedLocationDetail?.exportData?.descriptionOfDefect || '',
        quantity: detectedLocationDetail?.exportData?.quantity || '',
        measurement: detectedLocationDetail?.exportData?.measurement || '',
        causeOfDetect: detectedLocationDetail?.exportData?.causeOfDetect || '',
        recommendation: detectedLocationDetail?.exportData?.recommendation || '',
        inspectedBy: detectedLocationDetail?.exportData?.inspectedBy || '',
        // inspectedBySignature: detectedLocationDetail?.exportData?.inspectedBySignature || '',
        checkedBy: detectedLocationDetail?.exportData?.checkedBy || '',
        // checkedBySignature: detectedLocationDetail?.exportData?.checkedBySignature || '',
        reportedVia: detectedLocationDetail?.exportData?.reportedVia || '',
        acknowledgement: detectedLocationDetail?.exportData?.acknowledgement || '',
        defectsVerifiedBy: detectedLocationDetail?.exportData?.defectsVerifiedBy || '',
        // defectsVerifiedBySignature: detectedLocationDetail?.exportData?.defectsVerifiedBySignature || '',
        // defectsVerifiedByDate: detectedLocationDetail?.exportData?.defectsVerifiedByDate || '',
        instructions: detectedLocationDetail?.exportData?.instructions || '',
        WSONo: detectedLocationDetail?.exportData?.WSONo || '',
        contractNo: detectedLocationDetail?.exportData?.contractNo || '',
        urgentDefect: detectedLocationDetail?.exportData?.urgentDefect || '',
        observationKIV: detectedLocationDetail?.exportData?.observationKIV || '',
        repaidMethod: detectedLocationDetail?.exportData?.repaidMethod || '',
        repeatedDefect: detectedLocationDetail?.exportData?.repeatedDefect || '',
        roadName: detectedLocationDetail?.roadName || '',
        sector: detectedLocationDetail?.exportData?.sector || '',
        element: detectedLocationDetail?.exportData?.element || '',
        defect: detectedLocationDetail?.exportData?.defect || '',
        defectSize: detectedLocationDetail?.exportData?.defectSize || ''
      });
    }
  }, [detectedLocationDetail, reset]);
  const onSubmit: SubmitHandler<FormValues> = (data) => {
    loadingExport();
    dispatch(
      downloadReportPdf({
        ...data,
        detectedLocationId: detectedLocationDetail?.id || '',
        folderName: detectedLocationDetail?.folderName || '',
        socketId: socket.current.id,
        roadName: data.roadName || detectedLocationDetail?.roadName || '',
        sector: data.sector || 'default sector',
        element: data.element || 'default element',
        defect: data.defect || 'default defect',
        defectSize: data.defectSize || 'default defect size',
        pixelMeasurement: pixelData ? pixelData.pixelMeasurement : {},
        lengthMeasurement: pixelData ? pixelData.lengthMeasurement : {}
      })
    ).then(() => {
      onData(100);
      messageApi.destroy();
      success();
      setTimeout(() => {
        onData(0);
      }, 1000);
      handleFetchDataPdf();
    });
    handleClose();
    reset();
  };

  const onDraft: SubmitHandler<FormValues> = (data) => {
    dispatch(
      saveAsDraftPdf({
        ...data,
        detectedLocationId: detectedLocationDetail?.id || '',
        folderName: detectedLocationDetail?.folderName || '',
        socketId: socket.current.id,
        roadName: data.roadName || detectedLocationDetail?.roadName || '',
        sector: data.sector || 'default sector',
        element: data.element || 'default element',
        defect: data.defect || 'default defect',
        defectSize: data.defectSize || 'default defect size',
        pixelMeasurement: pixelData ? pixelData.pixelMeasurement : {},
        lengthMeasurement: pixelData ? pixelData.lengthMeasurement : {}
      })
    ).then(() => {
      handleFetchDataPdf();
      messageApi.success('Save as draft successfully');
    });
    handleClose();
    reset();
  };

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_API_URL || '', {
      path: '/lighthaus/backend/socket.io',
      transports: ['websocket', 'polling'],
      rejectUnauthorized: false
    });

    socket.current.on('process_export', (data: any) => {
      if (data === null) return;
      onData(data);
    });

    return () => socket.current.disconnect();
  }, []);

  const loadingExport = () => {
    return messageApi.loading(`Exporting...`, 0);
  };

  const success = () => {
    messageApi.success('Exported successfully');
  };

  const handleClose = () => {
    dispatch(toggleShowExportPdfModal(false));
  };

  return (
    <div>
      {contextHolder}
      <Modal show={isShowModal} onHide={handleClose} fullscreen={'md-down'} size={'lg'}>
        <Modal.Body>
          <Container>
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col span="4">
                  <small>Date: {DateTime.now().toFormat('yyyy-MM-dd')}</small>
                </Col>
                <Col span="4" className="text-center">
                  <small> {detectedLocationDetail?.folderName}</small>
                </Col>
                <Col span="4"></Col>
              </Row>
              <Row>
                <Col lg="12">
                  <h4 className="text-center"> Defect Record Sheet</h4>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg="6">
                  <InputGroup className="mb-3" size="sm">
                    <InputGroup.Text id="basic-addon1">Contact:</InputGroup.Text>
                    <Form.Control placeholder="Enter Contact No." {...register('contractNo')} />
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="2" className="border border-left-none text-center">
                  <div className="mt-2"> Section A</div>
                </Col>
                <Col sm="10" className="border">
                  <Container className="mt-2">
                    <Row className="mb-3">
                      <Form.Group as={Col} md="8" lg="8" controlId="validationCustom01">
                        <Form.Label>Defect Reference No</Form.Label>
                        <Form.Control readOnly type="text" value={detectedLocationDetail?.folderName} />
                      </Form.Group>
                      <Form.Group as={Col} md="4" lg="4" controlId="validationCustom02">
                        <Form.Label>Date</Form.Label>
                        <Form.Control
                          readOnly
                          type="text"
                          value={
                            detectedLocationDetail?.date && DateTime.fromFormat(detectedLocationDetail?.date, 'yyyyMMdd').toFormat('yyyy LLL dd')
                          }
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} lg="12" controlId="validationCustom04">
                        <Form.Label>Location</Form.Label>
                        <Form.Control
                          className="placeholder-color"
                          placeholder={detectedLocationDetail?.roadName}
                          style={{ backgroundColor: '#b2b5be' }}
                          {...register('roadName')}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} lg="12" controlId="validationCustom04">
                        <Form.Label>Sector</Form.Label>
                        <Form.Control
                          className="placeholder-color"
                          placeholder={'New sector'}
                          style={{ backgroundColor: '#b2b5be' }}
                          {...register('sector')}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} lg="12" controlId="validationCustom04">
                        <Form.Label>Element</Form.Label>
                        <Form.Control
                          className="placeholder-color"
                          placeholder={'New Element'}
                          style={{ backgroundColor: '#b2b5be' }}
                          {...register('element')}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} lg="12" controlId="validationCustom04">
                        <Form.Label>Defect</Form.Label>
                        <Form.Control
                          className="placeholder-color"
                          placeholder={'New Defect'}
                          style={{ backgroundColor: '#b2b5be' }}
                          {...register('defect')}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} lg="12" controlId="validationCustom04">
                        <Form.Label>Defect size</Form.Label>
                        <Form.Control
                          className="placeholder-color"
                          placeholder={'New Defect size'}
                          style={{ backgroundColor: '#b2b5be' }}
                          {...register('defectSize')}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} lg="12" controlId="validationCustom04">
                        <Form.Label>Defect location</Form.Label>
                        <Form.Control readOnly type="text" value={`${detectedLocationDetail?.latitude}/${detectedLocationDetail?.longitude}`} />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} lg="12" controlId="validationCustom04">
                        <Form.Label>Urgent Defect (Y / N)</Form.Label>
                        <Form.Control type="text" {...register('urgentDefect')} />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} lg="12" controlId="validationCustom04">
                        <Form.Label>Observation (to KIV) / Action Required</Form.Label>
                        <Form.Control type="text" {...register('observationKIV')} />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} lg="12" controlId="validationCustom04">
                        <Form.Label>Repaid method</Form.Label>
                        <Form.Control type="text" {...register('repaidMethod')} />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} lg="12" controlId="validationCustom04">
                        <Form.Label>Repeated Defect (Y / N)</Form.Label>
                        <Form.Control type="text" {...register('repeatedDefect')} />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} lg="12" controlId="validationCustom05">
                        <Form.Label>Type of Facility </Form.Label>
                        <Form.Control type="text" {...register('typeOfFacility')} maxLength={100} />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} lg="12" controlId="validationCustom06">
                        <Form.Label>Description of Defect</Form.Label>
                        <Form.Control type="text" {...register('descriptionOfDefect')} maxLength={100} />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} lg="12" controlId="validationCustom07">
                        <Form.Label>Quantity</Form.Label>
                        <Form.Control type="text" {...register('quantity')} maxLength={100} />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} lg="12" controlId="validationCustom08">
                        <Form.Label>Measurement</Form.Label>
                        <Form.Control type="text" {...register('measurement')} maxLength={100} />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} lg="12" controlId="validationCustom09">
                        <Form.Label>Cause of defect</Form.Label>
                        <Form.Control type="text" {...register('causeOfDetect')} maxLength={100} />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} lg="12" controlId="validationCustom10">
                        <Form.Label>Recommendation</Form.Label>
                        <Form.Control type="text" {...register('recommendation')} maxLength={100} />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} md="8" lg="8" controlId="validationCustom11">
                        <Form.Label>Inspected By</Form.Label>
                        <Form.Control type="text" {...register('inspectedBy')} maxLength={100} />
                      </Form.Group>
                      <Form.Group as={Col} md="4" lg="4" controlId="validationCustom12">
                        <Form.Label>Signature</Form.Label>
                        <SignPad
                          handleChange={(data: string) => {
                            setValue('inspectedBySignature', data);
                          }}
                          {...register('inspectedBySignature')}></SignPad>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} md="8" lg="8" controlId="validationCustom13">
                        <Form.Label>Checked By</Form.Label>
                        <Form.Control type="text" {...register('checkedBy')} maxLength={100} />
                      </Form.Group>
                      <Form.Group as={Col} md="4" lg="4" controlId="validationCustom14">
                        <Form.Label>Signature</Form.Label>
                        <SignPad
                          handleChange={(data: string) => {
                            setValue('checkedBySignature', data);
                          }}
                          {...register('checkedBySignature')}></SignPad>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Col className="text-center">
                        <small> Defects reported to DRC/RARL/RIA/Other agencies on:</small>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} lg="12" controlId="validationCustom15">
                        <Form.Label>
                          Reported via
                          <br />
                          (Method/ Agency/ Date/ Time)
                        </Form.Label>
                        <Form.Control type="text" {...register('reportedVia')} maxLength={100} />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} lg="12" controlId="validationCustom16">
                        <Form.Label>
                          Acknowledgement
                          <br />
                          (Method/ Date/ Time)
                        </Form.Label>
                        <Form.Control type="text" {...register('acknowledgement')} maxLength={100} />
                      </Form.Group>
                    </Row>
                  </Container>
                </Col>
                <Col sm="2" className="border border-left-none text-center">
                  <div className="mt-2"> Section B</div>
                </Col>
                <Col sm="10" className="border">
                  <Container className="mt-2">
                    <Row className="mb-3">
                      <Form.Group as={Col} md="8" lg="8" controlId="validationCustom17">
                        <Form.Label>Defects Verified by</Form.Label>
                        <Form.Control type="text" {...register('defectsVerifiedBy')} maxLength={100} />
                      </Form.Group>
                      <Form.Group as={Col} md="4" lg="4" controlId="validationCustom18">
                        <Form.Label>Signature</Form.Label>

                        <SignPad
                          handleChange={(data: string) => {
                            setValue('defectsVerifiedBySignature', data);
                          }}
                          {...register('defectsVerifiedBySignature')}></SignPad>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Col sm="8"></Col>
                      <Form.Group as={Col} md="4" lg="4" controlId="validationCustom19">
                        <Form.Label>Date</Form.Label>
                        <Form.Control readOnly type="text" {...register('defectsVerifiedByDate')} value={DateTime.now().toFormat('dd LLL yyyy')} />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} lg="12" controlId="validationCustom20">
                        <Form.Label>Instructions</Form.Label>
                        <Form.Control type="text" {...register('instructions')} maxLength={100} />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} lg="12" controlId="validationCustom21">
                        <Form.Label>WSO No.</Form.Label>
                        <Form.Control type="text" {...register('WSONo')} maxLength={100} />
                      </Form.Group>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleSubmit(onDraft)}>
            Save as Draft
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit(onSubmit)} disabled={isLoading}>
            {isLoading ? 'Loading...' : 'Export'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PdfInformationFormModal;
